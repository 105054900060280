<template>
	<div class="content page">
		<div class="topBox">
			<div class="titleBox">
				<div class="titBottom">{{ lang == 6 ? '集团新闻' : 'Group News' }}</div>
				<div class="yellowLine"></div>
			</div>
			<div class="selectBox">
				<div class="select">
					<el-select clearable @change="page = 1; getArticleList()" v-model="year"
						:popper-append-to-body="false" :placeholder="lang == 6 ? '请选择' : 'Please select'">
						<el-option v-for="(item, index) in yearList" :key="item.year_id" :label="item.year_id"
							:value="item.year_id">
						</el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="newsIndex-bottom">
			<div class="item" v-for="(item, index) in list" :key="index"
				@click="$router.push(`/brandStory/news/details/${item.id}`)">
				<div class="item-text">
					<div class="newsIndex-bottom-text">
						<p class="title">{{ item.title }}</p>
						<p class="text" v-html="item.describe" style="-webkit-box-orient: vertical;"></p>
					</div>
					<div class="bottom">
						<p><i class="el-icon-time"></i> {{ item.business_time }}</p>
						<p class="big">{{ lang == 6 ? '了解更多' : 'read more' }} ></p>
					</div>
				</div>
				<div class="imgBox">
					<img :src="item.image" />
				</div>
			</div>
			<el-pagination background layout="prev, pager, next" :page-size="limit" :total="total" :current-page="page"
				@current-change="changePage">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import mixinLang from '../../../components/mixin.js'
	export default {
		name: 'BrandStoryNewsList',
		metaInfo: {
			title: '宝爱捷(中国)汽车投资有限公司', // set a title
			meta: [{ // set meta
					name: 'keyWords',
					content: '宝爱捷新闻，宝马，BMW，baoma，奥迪，Audi，aodi，宾利，binli，bentley，兰博基尼，lamborghini，牛头，保时捷，porsche，mini，豪车'
				},
				{
					name: 'description',
					content: ''
				}
			]
		},
		mixins: [mixinLang],
		data() {
			return {
				year: "",
				yearList: [],
				list: [],
				page: 1,
				limit: 8,
				total: 1
			}
		},
		beforeRouteLeave(to, form, next) {
			if (to.name && to.name === 'brandStoryNewsDetails') {
				sessionStorage.setItem('newListPage', this.page)
			} else {
				sessionStorage.removeItem('newListPage')
			}
			next()
		},
		methods: {
			getInfo() {
				if (sessionStorage.getItem('newListPage')) {
					this.page = Number(sessionStorage.getItem('newListPage')) || 1
				}
				this.getArticleList()
				this.$api.getYearList({
					cate_no: 'b7567587',
					form: 'all'
				}).then(res => {
					this.yearList = res.list
				})
			},
			getArticleList() {
				this.$api.getArticleList({
					cate_no: 'b7567587',
					form: 'all',
					year: this.year
				}, {
					page: this.page,
					limit: this.limit
				}).then(res => {
					this.list = res.list
					this.total = Number(res.paginated.count)
				})
			},
			changePage(page) {
				this.page = page
				sessionStorage.removeItem('newListPage')
				this.getArticleList()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content {
		position: relative;
		padding-top: 200px;
	}

	.topBox {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		padding: 0 111px 50px;
		// margin: ;
	}

	.titleBox .titBottom {
		color: #FFF;
		text-transform: uppercase;
	}

	.selectBox {
		color: #3D3D3D;
		width: 250px;
		border-bottom: 1px solid #999999;
		font-family: SourceHanSansCN-Regular, sans-serif;
		font-size: 32px;
		font-weight: normal;
		display: flex;

		.select {
			flex: 1;
			position: relative;
			text-align: center;

			i {
				margin-left: 30px;
				color: #999999;
			}

			.optionBox {
				position: absolute;
				top: 49px;
				background-color: #FFF;
				border: 1px solid #999999;
				line-height: 60px;
				font-family: SourceHanSansCN-Regular, sans-serif;
				font-size: 32px;
				width: 100%;
				max-height: 400px;
				overflow-y: scroll;
				border-radius: 0px 0px 16px 16px;
				box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
				color: #3D3D3D;
				font-family: SourceHanSansCN-Normal, sans-serif;
				font-size: 32px;

				p {
					border-bottom: #DDDDDD solid 1px;
				}
			}
		}
	}

	.newsIndex-bottom {
		position: relative;
		background-color: #FFFF;

		.item {
			height: 480px;
			background: #F2F2F2;
			position: relative;
			display: flex;
			padding: 76px 111px;
			justify-content: space-between;
			cursor: pointer;

			&:nth-child(even) {
				background-color: #FFF;
			}

			.item-text {
				width: 1127px;
				position: relative;
			}

			.imgBox {
				position: relative;

				&::after {
					content: '';
					display: block;
					position: absolute;
					width: 200px;
					height: 280px;
					top: -10px;
					left: -10px;
					background-color: #97233F;
					z-index: 1;
				}

				img {
					width: 520px;
					height: 320px;
					z-index: 4;
					position: relative;
				}
			}


			.newsIndex-bottom-text {
				padding: 0 23px;

				.title {
					font-family: Montserrat-Bold, sans-serif;
					font-size: 26px;
					font-weight: bold;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin: 0px 0 30px;
				}

				.text {
					font-family: SourceHanSansCN-Normal, sans-serif;
					font-size: 18px;
					font-weight: 350;
					line-height: 36px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 4;
					overflow: hidden;
					/* autoprefixer: off */
					-webkit-box-orient: vertical;
					/* autoprefixer: on */
					// background-color: chartreuse;
					height: 140px;
				}
			}

			.bottom {
				position: absolute;
				bottom: 31px;
				padding: 0 23px;
				display: flex;
				justify-content: space-between;
				color: #999999;
				font-family: SourceHanSansCN-Normal, sans-serif;
				font-size: 18px;
				font-weight: 350;
				width: 100%;
				align-items: center;

				.big {
					font-family: SourceHanSansCN-Bold, sans-serif;
					font-size: 22px;
					font-weight: bold;
					color: #333333;
					cursor: pointer;
					text-transform: uppercase;
				}
			}

		}

		.btn {
			position: absolute;
			width: 304px;
			height: 77px;
			box-sizing: border-box;
			border: 4px solid #97233F;
			font-size: 18px;
			font-weight: bold;
			color: #97233F;
			line-height: 67px;
			text-align: center;
			bottom: 51px;
			left: calc(50% - 152px);
		}
	}

	.el-pagination {
		text-align: center;
		margin: 100px 0;

		/deep/ .active {
			background-color: #97233F !important;
		}

		/deep/ .number:hover {
			color: #97233F !important;
		}
	}

	.el-select {
		border: none;
		margin-top: 20px;
		font-family: SourceHanSansCN-Regular, sans-serif;
		font-size: 20px;
		font-weight: normal;
		color: #666666;
	}

	/deep/.el-input__inner {
		width: 100%;
		background-color: rgba(0, 0, 0, 0);
		border: none;
		font-family: SourceHanSansCN-Regular, sans-serif;
		font-size: 20px;
		font-weight: normal;
		color: #666666;

		&::placeholder {
			color: #666666;
		}
	}

	/deep/.el-input__icon {
		color: #666666;
	}

	/deep/ .el-select-dropdown__item {
		font-family: SourceHanSansCN-Regular, sans-serif;
		// font-size: 32px !important;
		font-weight: normal;
		color: #666666;
		height: 60px !important;
		line-height: 60px;
	}

	/deep/ .el-icon-date {
		color: #97233F;
		margin-right: 20px;
	}

	/deep/.el-input__inner {
		margin-left: 30px;
	}

	// /deep/.el-date-editor.el-input,
	// .el-date-editor.el-input__inner {
	// 	width: 100% !important;
	// }
</style>